@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("assets/fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes diamond {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-30px);
  }

  100% {
    transform: translateY(0px);
  }
}

/*
=============== 
Variables
===============
*/

:root {
  --root-light: hsla(0, 0%, 100%, 1);
  --root-dark: hsla(228, 5%, 36%, 1);
  --root-blue: hsla(230, 74%, 62%, 1);

  --faint-light: hsla(0, 0%, 100%, 0.8);
  --faint-dark: hsla(228, 5%, 36%, 0.8);
  --faint-blue: hsla(230, 74%, 62%, 0.5);

  --transparent-light: hsla(0, 0%, 100%, 0.4);
  --transparent: hsla(0, 0%, 100%, 0.07);
  --transparent-blue: hsla(230, 74%, 62%, 0.04);

  --transition: all 0.2s linear;
}

/*
=============== 
Global Styles
===============
*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: Montserrat, sans-serif;
  background: var(--root-light);
  color: var(--faint-dark);
  line-height: 1.4;
  font-size: 16px;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
img:not(.nav-logo) {
  width: 100%;
  display: block;
}

h1,
h2,
h3,
h4 {
  line-height: 1.1;
  font-weight: 600;
}
h1 {
  font-size: 36px;
}
h2 {
  font-size: 32px;
}
h3 {
  font-size: 22px;
}
h4 {
  font-size: 18px;
}
p {
  color: var(--faint-dark);
}

@media screen and (max-width: 800px) {
  h1 {
    font-size: 34px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 16px;
  }
  body {
    font-size: 14px;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1.2;
  }
}
@media screen and (max-width: 430px) {
  h1,
  h2,
  h3,
  h4 {
    line-height: 1.3;
  }
  h2 {
    font-size: 24px;
  }
  p {
    font-size: 10px;
  }
  .btn {
    font-size: 14px;
  }
}
/*  global classes */

.btn {
  text-transform: capitalize;
  background: var(--root-blue);
  color: var(--root-light);
  padding: 12px 22px;
  display: inline-block;
  font-weight: 500;
  transition: var(--transition);
  font-size: 16px;
  border-radius: 100px;
  cursor: pointer;
}
.btn:hover {
  box-shadow: 12px 12px 20px 0px hsla(230, 74%, 62%, 0.22);
}
/* section */
.section {
  padding: 60px 0;
  overflow: hidden;
  scroll-margin-top: 4rem;
}
.section-title {
  margin-bottom: 42px;
}
.section-center {
  width: 95vw;
  margin: 0 auto;
  max-width: 1240px;
  text-align: left;
}
@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
  }
}
@media screen and (max-width: 768px) {
  .section-center {
    text-align: center;
  }
}

/*
=============== 
Hero
===============
*/

.hero {
  background: var(--root-light) url("./assets/images/blockchain.svg") no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.diamond,
.diamond-bg {
  position: absolute;
  left: 50vw;
  width: 34px;
  height: 34px;
  background: url("./assets/images/diamond.svg") center no-repeat;
  background-size: contain;
  animation: diamond 5s infinite;
  bottom: 0;
}
.diamond-bg {
  background: var(--transparent-blue) url("./assets/images/diamond.svg") center
    no-repeat;
  border-radius: 50%;
  background-size: 50%;
}
.hero .diamond-bg:nth-child(2) {
  left: 60%;
  top: 26%;
  width: 60px;
  height: 60px;
  animation-delay: 2s;
}
.hero .diamond-bg:nth-child(3) {
  left: 5%;
  top: 88%;
  width: 80px;
  height: 80px;
  animation-delay: 5s;
}
.hero .diamond:nth-child(4) {
  left: 92%;
  top: 20%;
  width: 60px;
  height: 60px;
  animation-delay: 3s;
}
.hero .diamond:nth-child(5) {
  left: 66%;
  top: 84%;
  width: 40px;
  height: 40px;
  animation-delay: 1s;
}
.hero .diamond:nth-child(6) {
  left: 12%;
  top: 25%;
  width: 40px;
  height: 40px;
  animation-delay: 4s;
}

.hero .diamond:nth-child(7) {
  left: 25%;
  top: 80%;
  width: 30px;
  height: 30px;
  animation-delay: 10s;
}
.hero-banner,
.ceo-banner {
  text-align: left;
  color: var(--root-blue);
  width: 90vw;
  max-width: 1170px;
  margin: 0 auto;
  z-index: 1;
}
.hero-banner h3,
.ceo-banner h3 {
  margin-top: 40px;
}
.hero-banner h1,
.ceo-banner h3 {
  max-width: 900px;
  color: var(--faint-blue);
  margin: 16px 0 42px 0;
}
.hero-banner h1 span,
.ceo-banner h1 span {
  color: var(--root-blue);
}
.hero-banner p {
  max-width: 740px;
  height: 84px;
  color: var(--root-blue);
  background: var(--transparent-blue);
  padding: 20px 22px 22px 38px;
  position: relative;
  border-radius: 6px;
}
.hero-banner p::after {
  content: "";
  position: absolute;
  top: 22px;
  left: 22px;
  width: 2px;
  height: 40px;
  background: var(--faint-blue);
}
.hero-btn {
  margin-left: 14px;
}

@media screen and (min-width: 375px) {
  .hero {
    min-height: 100vh;
    background-size: cover;
    background-position: 350px 340px;
  }
}
@media screen and (min-width: 768px) {
  .hero {
    min-height: calc(100vh - 300px);
  }
  .hero-banner {
    padding: 0;
  }
  .hero-banner p {
    max-width: 45rem;
  }
}
@media screen and (min-width: 1360px) {
  .hero {
    min-height: calc(100vh - 243px);
    background-size: calc(100vh - 90px - 244px);
    background-position: calc(100% - 3vw) 90px;
  }
}

/*
=============== 
Advantages
===============
*/

.advantages {
  background: var(--root-blue);
  position: relative;
  z-index: 1;
}
.advantages-center {
  display: grid;
  gap: 16px;
}
.advantage {
  color: var(--root-light);
  min-width: 298px;
  display: flex;
  text-align: left;
}
.advantage-title {
  margin-bottom: 10px;
  display: flex;
  gap: 6px;
  align-items: center;
}
.advantage-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
}
.advantage-icon i {
  display: block;
  width: 24px;
  height: 24px;
}
.advantage-icon i.heart {
  background: url("./assets/images/heart.svg") center no-repeat;
}
.advantage-icon i.star {
  background: url("./assets/images/star.svg") center no-repeat;
}
.advantage-icon i.chart {
  background: url("./assets/images/chart.svg") center no-repeat;
}
.advantage-icon i.smile {
  background: url("./assets/images/smile.svg") center no-repeat;
}
.advantage-text {
  color: var(--root-light);
}

@media screen and (min-width: 375px) {
  .advantages-center {
    grid-template-columns: 1fr;
    gap: 22px;
  }
  .advantage {
    text-align: center;
    margin: auto;
  }
  .advantage-title {
    justify-content: center;
  }
}

@media screen and (min-width: 768px) {
  .advantages-center {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 1360px) {
  .advantages-center {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

/*
=============== 
Services
===============
*/
.services-center {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}
.service-card {
  background: var(--transparent-blue);
}
.service-info {
  padding: 42px 32px 136px 32px;
  height: 100%;
  position: relative;
}
.service-title {
  color: var(--root-blue);
  margin-bottom: 22px;
}
.service-description {
  margin-bottom: 16px;
}
.service-info p {
  margin-bottom: 9px;
}
.service-footer .btn {
  position: absolute;
  bottom: 42px;
  left: 32px;
}

@media screen and (max-width: 768px) {
  .services-center {
    grid-template-columns: 1fr;
  }
  .service-footer .btn {
    left: calc(50% - 62px);
  }
}

/*
=============== 
Contacts
===============
*/
.contacts {
  color: var(--root-light);
  padding: 0;
}
.contacts-center {
  background: var(--root-blue) url("./assets/images/contacts-bg.png") center
    no-repeat;
  border-radius: 6px;
  margin-bottom: 32px;
  padding: 42px 0;
  text-align: center;
  position: relative;
}
.contacts .diamond,
.contacts .diamond_bg {
  position: absolute;
  width: 66px;
  height: 66px;
  background: url("./assets/images/diamond.svg") center no-repeat;
  background-size: contain;
  animation: diamond 5s infinite;
  bottom: 0;
}
.contacts .diamond_bg {
  background: var(--transparent) url("./assets/images/diamond.svg") center
    no-repeat;
  border-radius: 50%;
  background-size: 50%;
}
.contacts .diamond_bg:nth-child(1) {
  left: 10%;
  top: 20%;
  animation-delay: 2s;
}
.contacts .diamond_bg:nth-child(2) {
  left: calc(90% - 66px);
  top: 20%;
  animation-delay: 2s;
}
.contacts .diamond:nth-child(3) {
  left: 30%;
  top: 70%;
  width: 54px;
  height: 54px;
  animation-delay: 3s;
}
.contacts .diamond:nth-child(4) {
  left: calc(70% - 54px);
  top: 70%;
  width: 54px;
  height: 54px;
  animation-delay: 3s;
}
.contacts-title {
  margin-bottom: 22px;
}
.contacts-description {
  color: var(--faint-light);
  max-width: 90vw;
  margin: 0 auto 16px;
}
.icon {
  width: 24px;
  height: 24px;
  display: block;
}
.contacts-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}
.contacts-icon {
  margin-right: 8px;
  transition: var(--transition);
}
.contacts .telegram {
  background: url("./assets/images/telegram-light.svg") center no-repeat;
}
.contacts .linkedin {
  background: url("./assets/images/linkedin-light.svg") center no-repeat;
}
.contacts .email {
  color: var(--root-light);
}

@media screen and (max-width: 992px) {
  .contacts .diamond,
  .contacts .diamond_bg {
    display: none;
  }
}

/*
=============== 
Footer
===============
*/
.footer {
  background: var(--root-blue);
  padding: 22px 0;
}
.footer-center {
  display: flex;
  gap: 12px;
  align-items: center;
  position: relative;
}
.copyright {
  color: var(--transparent-light);
  font-size: 14px;
  font-weight: 500;
  margin-top: 4px;
}
.copyright span {
  margin-left: 0.3rem;
}

.footer .email {
  position: absolute;
  right: 0;
  bottom: 4px;
  font-size: 16px;
  font-weight: 500;
  color: var(--root-light);
}
@media screen and (max-width: 430px) {
  .footer-center {
    flex-direction: column;
  }
  .footer .email {
    position: relative;
  }
}

/*
=============== 
Navbar
===============
*/

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--root-light);
  z-index: 2;
  height: 90px;
  display: flex;
  align-items: center;
}
.nav-icons {
  display: none;
}
.nav-center {
  width: 95vw;
  max-width: 1240px;
  margin: 0 auto;
}
.nav-header {
  display: flex;
  justify-content: space-between;
  gap: 42px;
  align-items: center;
}
.nav-toggle {
  background: transparent;
  border: none;
  outline: none;
  font-size: 1.5rem;
  color: var(--root-blue);
  cursor: pointer;
  transition: var(--transition);
}
.nav-toggle:hover {
  transform: scale(1.2);
}
.nav-link {
  display: block;
  padding: 2rem 3rem;
  text-transform: capitalize;
  transition: var(--transition);
  color: var(--faint-light);
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
}
.nav-link:hover {
  color: var(--root-light);
}
/* nav toggle functionality */
.nav-links {
  position: absolute;
  align-self: end;
  height: 0;
  overflow: hidden;
  transition: var(--transition);
}
.show-links {
  height: 280px;
  top: 34px;
  right: 42px;
  background: var(--root-blue);
  border-radius: 6px;
  box-shadow: 12px 12px 20px 0px hsla(230, 74%, 62%, 0.22);
}
.close-btn {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 30px;
  right: 30px;
  background: url("./assets/images/close.svg") center no-repeat;
  background-size: cover;
  display: none;
}
/* nav at bigger screen size */
@media screen and (min-width: 992px) {
  /* hide nav toggle button */
  .nav-toggle {
    display: none;
  }
  /* show links */
  .nav-links {
    position: relative;
    height: auto;
    display: flex;
  }
  .nav-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* optional */
    flex-wrap: wrap;
  }
  .nav-header {
    padding: 0 0;
  }
  .nav-link {
    padding: 0 0;
    font-size: 16px;
    color: var(--faint-blue);
  }
  .nav-link:hover {
    padding: 0;
    color: var(--root-blue);
    background: transparent;
  }
  .nav-icons {
    display: flex;
    align-items: center;
  }

  .nav-icons li {
    height: 100%;
  }

  .nav-link {
    margin-right: 22px;
  }
  .nav-icon {
    margin-right: 8px;
    width: 24px;
    display: block;
    transition: var(--transition);
  }

  .nav-icon .telegram {
    background: url("./assets/images/telegram-dark.svg") center no-repeat;
  }

  .nav-icon .linkedin {
    background: url("./assets/images/linkedin-dark.svg") center no-repeat;
  }
}

@media screen and (max-width: 430px) {
  .show-links {
    height: 100vh;
    width: 100vw;
    top: 0;
    right: 0;
    border-radius: 0;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .nav-link {
    font-size: 30px;
    text-align: center;
    font-weight: 600;
  }
  .close-btn {
    display: block;
  }
}

.ceo {
  min-height: 100vh;
  background-size: 50%;
  background-position: 200% 50%;
}
.ceo-banner {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ceo-banner h3 {
  max-width: 100%;
  margin-bottom: 16px;
}
.avatar {
  width: 160px;
  height: 160px;
  background-size: cover;
  border-radius: 50%;
  overflow: hidden;
}
.qr {
  background: url("./assets/images/qr.svg") center no-repeat;
  background-size: contain;
  width: 200px;
  height: 200px;
}
